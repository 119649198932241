@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--green-dark: #b0bd92;
		--normal-green: #d1d9c0;
		--primary-bg: #ece9e1;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		opacity: 1;
		position: relative;
		left: 4px;
	}
	body {
		background-color: var(--primary-bg);
		overflow-x: hidden;
		overflow-y: scroll;
	}
	ul {
		margin-block-start: 0;
	}
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 4px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--green-dark);
		opacity: 0.7;
		border-radius: 2px;
	}
}

.mirror {
	transform: scaleX(-1);
}

nav {
	box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.banner-shadow {
	box-shadow: 0px -3px 6px 0px rgb(0 0 0 / 22%),
		0 3px 6px rgb(183 183 183 / 0%);
}

.bitter-500 {
	font-family: "Bitter";
	font-weight: 500;
}

.roboto-900 {
	font-family: "Roboto";
	font-weight: 900;
}

@font-face {
	font-family: "Roboto";
	font-weight: 900;
	font-display: auto;
	src: local("Roboto-Black"),
		url(/fonts/roboto-v29-latin-900.woff2) format("woff2");
}

@font-face {
	font-family: "Bitter";
	font-weight: 500;
	font-display: auto;
	src: url(/fonts/bitter-v25-latin-ext_latin-500italic.woff2) format("woff2");
}

.accordion-content {
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 250ms;
}

.accordion-content > div {
	overflow: hidden !important;
}

.accordion-content-open {
	grid-template-rows: 1fr;
}

.webkit-highlight-fix {
	-webkit-tap-highlight-color: transparent !important;
}
